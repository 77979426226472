var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-layout",
        {
          staticClass: "page-layout",
          attrs: { "text-xs-center": "", wrap: "", "align-center": "" }
        },
        [
          _c("v-flex", { staticClass: "text-center", attrs: { "mb-4": "" } }, [
            _c("img", {
              staticClass: "learnlink-logo",
              attrs: {
                src: require("../assets/learnlink-logo.png"),
                alt: "Learnlink logo"
              }
            }),
            _c("h5", { staticClass: "subheading font-weight-regular" }, [
              _vm._v(" Tilrettelagt undervisning på nett ")
            ])
          ]),
          _c(
            "v-flex",
            { attrs: { "mb-2": "", xs12: "" } },
            [
              _c(
                "v-layout",
                { attrs: { "align-center": "" } },
                [
                  _c("v-flex", { attrs: { xs12: "" } }, [
                    _c("p", { staticClass: "text-center" }, [
                      _vm._v(
                        " Lag ditt eget rom, eller skriv inn navnet på rommet du skal delta i. "
                      )
                    ])
                  ])
                ],
                1
              ),
              _c(
                "v-layout",
                { staticClass: "room-name", attrs: { "align-center": "" } },
                [
                  _c(
                    "v-flex",
                    { staticClass: "mr-1", attrs: { xs6: "", sm5: "" } },
                    [
                      _vm.$vuetify.breakpoint.smAndUp
                        ? _c("h3", { staticClass: "text-center" }, [
                            _vm._v(" online.learnlink.no/ ")
                          ])
                        : _c("h3", { staticClass: "text-center title" }, [
                            _vm._v(" online.learnlink.no/ ")
                          ])
                    ]
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs4: "", sm3: "" } },
                    [
                      _c("v-text-field", {
                        attrs: { label: "romnavn" },
                        on: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.openRoom()
                          }
                        },
                        model: {
                          value: _vm.roomName,
                          callback: function($$v) {
                            _vm.roomName = $$v
                          },
                          expression: "roomName"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-flex",
            { staticClass: "text-center", attrs: { "mb-5": "", xs12: "" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { disabled: !_vm.roomName },
                  on: {
                    click: function($event) {
                      return _vm.openRoom()
                    }
                  }
                },
                [_vm._v(" Start nettundervisning ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }