var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-card-title",
        { staticClass: "headline error", attrs: { "primary-title": "" } },
        [
          _c("h6", [
            _vm._v(
              " Videochat er ikke støttet i " +
                _vm._s(_vm.$store.state.device.browserName) +
                " for iOS "
            )
          ])
        ]
      ),
      _c("v-card-text", { staticClass: "text-center" }, [
        _c("p", [
          _c("strong", [
            _vm._v(
              " På iOS-enheter (iPad og iPhone) er Safari den eneste støttede nettleseren for video-chat. "
            )
          ])
        ]),
        _c("p", [
          _vm._v(
            " Du kan fremdeles bruke tjenesten, men hvis du opplever problemer, anbefaler vi å bytte til Safari. "
          )
        ])
      ]),
      _c(
        "v-card-actions",
        [
          _c(
            "v-btn",
            {
              staticClass: "ma-auto",
              attrs: { color: "info" },
              on: {
                click: function($event) {
                  return _vm.$emit("closeDialog")
                }
              }
            },
            [_vm._v(" Jeg forstår ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }