import Moment from 'moment';
import * as _Sentry from '@sentry/browser';
import AudioVisual from 'vue-audio-visual';
import Vue from 'vue';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/database';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/performance';
import 'firebase/analytics';

import './assets/learnlink.global.styl';
import vuetify from './plugins/vuetify';
import credentials from './config/credentials';
import router from './router';
import store from './store';
import App from './App.vue';

firebase.initializeApp(credentials.firebase);

firebase.performance();
firebase.analytics();

Vue.config.productionTip = false;

Moment.locale('nb');

Vue.use(AudioVisual);

Vue.prototype.$auth = firebase.auth();
Vue.prototype.$db = firebase.database();
Vue.prototype.$fs = firebase.firestore();
Vue.prototype.$providers = firebase.auth;
Vue.prototype.$storage = firebase.storage();
Vue.prototype.$sentry = _Sentry;
Vue.prototype.$moment = Moment;

Vue.prototype.$handleError = (e) => {
  if (e.name) console.error(e.name);
  if (e.message) console.error(e.message);

  if (e) {
    console.error(e); // eslint-disable-line

    if (e instanceof Error) {
      _Sentry.withScope((scope) => {
        if (e.response) {
          if (e.response.data && e.response.headers) {
            // The maximum limit for the event payload is 200KB.
            if (e.response.headers['content-length'] < 2 * 10 ** 5) {
              scope.setExtra('Extra data', e.response.data);
            }
            else
              scope.setExtra(
                'Extra data',
                'The response data exceeded 200KB, and is therefore not shown here. Sentry\'s maximum limit for the event payload is 200KB',
              );
          }
        }
        else if (e.request) {
          scope.setExtra('Extra data', 'No response received from the API');
        }
        else {
          scope.setExtra('Extra data', 'No extra data');
        }
        _Sentry.captureException(e);
      });
    }
    else _Sentry.captureMessage(JSON.stringify(e));
  }
  else {
    _Sentry.captureMessage(
      '$handleError called without an error object: ' + JSON.stringify(e),
    );
  }
};

Vue.prototype.$signOut = () => {
  return firebase
    .auth()
    .signOut()
    .then(() => store.commit('signOut'));
};

Vue.config.errorHandler = (e) => {
  console.error(e); // eslint-disable-line
  _Sentry.captureException(e);
};

new Vue({
  el: '#app',
  router,
  store,
  vuetify,
  template: '<App/>',
  components: { App },
});
