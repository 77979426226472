import LogRocket from 'logrocket';

let userUID = '';

export function addUserUIDtoLogRocket(uid) {
  userUID = uid;
  sendInfoToLogRocket(uid, {});
}

export function addInfoToLogRocket(payload) {
  let uid = userUID;
  if (uid === '') uid = window.analytics.user().anonymousId();
  sendInfoToLogRocket(uid, payload);
}

function sendInfoToLogRocket(uid, payload) {
  if (window.location.hostname === 'localhost') return;
  LogRocket.identify(uid, payload);
}

