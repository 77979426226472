var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c("v-main", [_c("router-view")], 1),
      _c("user-drawer"),
      _c(
        "v-dialog",
        {
          attrs: { scrollable: "", "max-width": "600px" },
          model: {
            value: _vm.browserWarningDialog,
            callback: function($$v) {
              _vm.browserWarningDialog = $$v
            },
            expression: "browserWarningDialog"
          }
        },
        [
          _c("browser-warning", {
            on: {
              closeDialog: function($event) {
                return _vm.setBrowserWarningDialogVisibility(false)
              }
            }
          })
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "600px" },
          model: {
            value: _vm.loginDialogVisible,
            callback: function($$v) {
              _vm.loginDialogVisible = $$v
            },
            expression: "loginDialogVisible"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("login", {
                on: {
                  cancel: function($event) {
                    return _vm.setLoginDialogVisibility(false)
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "600px" },
          model: {
            value: _vm.alertVisibility,
            callback: function($$v) {
              _vm.alertVisibility = $$v
            },
            expression: "alertVisibility"
          }
        },
        [
          _c(
            "v-alert",
            { staticStyle: { "margin-bottom": "0" }, attrs: { type: "error" } },
            [
              _c(
                "v-layout",
                [
                  _c("v-flex", { attrs: { "8": "" } }, [
                    _c("strong", [_vm._v(_vm._s(_vm.alert.headline))]),
                    _c("br"),
                    _c("small", [
                      _vm._v(
                        " " + _vm._s(_vm.$store.state.ui.alert.message) + " "
                      ),
                      _vm.alert.link
                        ? _c(
                            "a",
                            {
                              staticStyle: { color: "white" },
                              attrs: { href: _vm.alert.link, target: "_blank" }
                            },
                            [_vm._v(" Klikk her for mer informasjon ")]
                          )
                        : _vm._e()
                    ])
                  ]),
                  _c(
                    "v-flex",
                    { attrs: { "4": "" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "white", text: "", large: "" },
                          on: {
                            click: function($event) {
                              return _vm.closeAlert()
                            }
                          }
                        },
                        [_vm._v(" Lukk ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }