<template>
  <v-container>
    <v-layout text-xs-center wrap class="page-layout" align-center>
      <v-flex mb-4 class="text-center">
        <img
            src="../assets/learnlink-logo.png"
            alt="Learnlink logo"
            class="learnlink-logo"
        />
        <h5 class="subheading font-weight-regular">
          Tilrettelagt undervisning på nett
        </h5>
      </v-flex>

      <v-flex mb-2 xs12>
        <v-layout align-center>
          <v-flex xs12>
            <p class="text-center">
              Lag ditt eget rom, eller skriv inn navnet på rommet du skal delta
              i.
            </p>
          </v-flex>
        </v-layout>
        <v-layout align-center class="room-name">
          <v-flex xs6 sm5 class="mr-1">
            <h3 v-if="$vuetify.breakpoint.smAndUp" class="text-center">
              online.learnlink.no/
            </h3>
            <h3 v-else class="text-center title">
              online.learnlink.no/
            </h3>
          </v-flex>
          <v-flex xs4 sm3>
            <v-text-field
                v-model="roomName"
                label="romnavn"
                @keyup.enter="openRoom()"
            />
          </v-flex>
        </v-layout>
      </v-flex>

      <v-flex mb-5 xs12 class="text-center">
        <v-btn :disabled="!roomName" @click="openRoom()">
          Start nettundervisning
        </v-btn>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      roomName: '',
    };
  },
  mounted() {
    window.analytics.page(this.name);
  },
  methods: {
    openRoom() {
      // If the room name contains unsupported characters (., /, Æ, Ø, Å, etc), replace them with valid chars.
      const sanitizedPath = this.roomName
          .replace(new RegExp('æ', 'g'), 'ae')
          .replace(new RegExp('ø', 'g'), 'o')
          .replace(new RegExp('å', 'g'), 'aa')
          .replace(new RegExp(/[$-/:-?{-~!"^_`\[\]]/, 'g'), ''); // eslint-disable-line

      this.$router.push('/' + sanitizedPath.toLowerCase());
    },
  },
};
</script>

<style scoped lang="stylus">
.page-layout
  background rgba(0, 155, 255, 0.74)
  height 40vh
  min-height 330px
  margin 20vh auto 0
  box-shadow rgb(150, 150, 150) 0 5px 38px
  border-radius 2px
  max-width 600px

.learnlink-logo
  width 300px

.subheading
  margin-top -10px
  color white

.room-name
  justify-content center

@media screen and (max-width: 375px)
  .room-name
    justify-content space-around

h1
h2
h3
p
label
.room-name
  color white !important
</style>
