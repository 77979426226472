<template>
  <v-navigation-drawer
    v-model="userDrawer"
    class="light-blue darken-4"
    width="100%"
    style="z-index: 100"
    dark
    stateless
    disable-resize-watcher
    app
    right
  >
    <v-container>
      <v-row class="fill-height">
        <v-list-item
          link
          class="drawer-close-button"
          @click="setUserDrawerActive(false)"
        >
          <v-list-item-icon>
            <v-icon>close</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-col>
          <h2 class="mb-8">
            Opptak fra timer
          </h2>

          <div v-if="recordings.length">
            <div
              v-for="recording in recordings"
              :key="recording.ID"
              class="mb-5"
            >
              <p>{{ getRecordingDate(recording) }}</p>
              <v-btn
                color="#009bff"
                :loading="!recording.downloadURL"
                :disabled="!recording.downloadURL"
                @click="openVideo(recording.downloadURL)"
              >
                Åpne
              </v-btn>
              <p v-if="!recording.downloadURL" class="helptext">
                Klargjør opptak. Vennligst vent. Dette kan ta opp til 5 minutter.
              </p>
            </div>
          </div>
          <div v-else>
            <p>Ingen opptak ennå.</p>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-navigation-drawer>
</template>

<script>
import axios from 'axios'
import { mapMutations } from 'vuex'

export default {
  name: 'UserDrawer',
  data() {
    return {
      recordings: []
    }
  },
  computed: {
    userDrawer: {
      set(visibility) {
        this.setUserDrawerActive(visibility)
      },
      get() {
        return this.$store.state.ui.userDrawer
      }
    },
    userId() {
      return this.$store.state.user.uid
    }
  },
  watch: {
    userId: function(value) {
      if (value) {
        this.fetchVideoFiles()
      }
    }
  },
  mounted() {
    this.fetchVideoFiles()
  },
  methods: {
    ...mapMutations(['setUserDrawerActive']),
    openVideo(url) {
      window.open(url, '_blank')
    },
    getRecordingDate(recording) {
      return this.$moment.unix(recording.created).format('LLLL')
    },
    async fetchVideoFiles() {
      if (!this.recordings.length && this.$store.state.user.uid) {
        try {
          const token = await this.$auth.currentUser.getIdToken()

          const recordingsRes = await axios.get(
            process.env.VUE_APP_API2 +
            '/recordings/' +
            this.$store.state.user.uid,
            {
              headers: {
                Authorization: 'Bearer ' + token
              }
            }
          )

          for (const r of recordingsRes.data) {
            try {
              r.downloadURL = await this.$storage
                .ref('46362322')
                .child(r.fileList)
                .getDownloadURL()
              // Pushing with downloadURL
              this.recordings.push(r)
            }
            catch (e) {
              // Pushing without downloadURL. Showing "processing" message instead.
              if (r.created > this.$moment().subtract(1, 'hours').unix()) {
                this.recordings.push(r)
              }
              this.$sentry.captureException(e)
            }
          }
        }
        catch (e) {
          this.$sentry.captureException(e)
        }
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
  h2, h5, p
    color white
    text-align left

  .main-column
    padding 5% 10%

  .drawer-close-button
    float right
    width 50px
    right 40px
    position absolute
</style>
