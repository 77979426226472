<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>

    <user-drawer />

    <v-dialog v-model="browserWarningDialog" scrollable max-width="600px">
      <browser-warning @closeDialog="setBrowserWarningDialogVisibility(false)" />
    </v-dialog>

    <v-dialog v-model="loginDialogVisible" max-width="600px">
      <v-card>
        <login @cancel="setLoginDialogVisibility(false)" />
      </v-card>
    </v-dialog>

    <v-dialog v-model="alertVisibility" max-width="600px">
      <v-alert type="error" style="margin-bottom: 0;">
        <v-layout>
          <v-flex 8>
            <strong>{{ alert.headline }}</strong>
            <br />
            <small>
              {{ $store.state.ui.alert.message }}
              <a v-if="alert.link" :href="alert.link" target="_blank" style="color: white;">
                Klikk her for mer informasjon
              </a>
            </small>
          </v-flex>
          <v-flex 4>
            <v-btn color="white" text large @click="closeAlert()">
              Lukk
            </v-btn>
          </v-flex>
        </v-layout>
      </v-alert>
    </v-dialog>
  </v-app>
</template>

<script>
import Vue from 'vue';
import { mapMutations } from 'vuex';
import * as Integrations from '@sentry/integrations';
import detectIt from 'detect-it';
import {
  browserName,
  isChrome,
  isMobile,
  isIOS,
  isMobileSafari,
  isSafari,
  isFirefox,
  osName,
  osVersion,
} from 'mobile-device-detect';

import credentials from './config/credentials';
import UserDrawer from './components/UserDrawer';
import Login from './components/Login';
import BrowserWarning from './components/BrowserWarning';
import LogRocket from 'logrocket';
import { addUserUIDtoLogRocket } from '@/services/logrocket';

export default {
  name: 'App',
  components: {
    BrowserWarning,
    Login,
    UserDrawer,
  },
  computed: {
    iOsNonSafari() {
      return isIOS && !isMobileSafari;
    },
    device() {
      return this.$store.state.device;
    },
    user() {
      return this.$store.state.user;
    },
    alert() {
      return this.$store.state.ui.alert;
    },
    alertVisibility: {
      set(visibility) {
        if (!visibility) {
          this.closeAlert();
        }
      },
      get() {
        return this.alert.active;
      },
    },
    loginDialogVisible: {
      set(visibility) {
        this.setLoginDialogVisibility(visibility);
      },
      get() {
        return this.$store.state.ui.loginDialogVisible;
      },
    },
    browserWarningDialog: {
      set(visibility) {
        this.setBrowserWarningDialogVisibility(visibility);
      },
      get() {
        return this.$store.state.ui.browserWarningDialog;
      },
    },
  },
  mounted() {
    const environment = credentials.firebase.projectId;

    if (window.location.hostname !== 'localhost') {
      this.$sentry.init({
        dsn: 'https://72275c8619644fd9b5483757fe3b5cb9@sentry.io/1438512',
        environment: environment.substring(10),
        integrations: [
          new Integrations.Vue({
            Vue,
            attachProps: true,
          }),
        ],
      });
      LogRocket.init('i77qy0/learnlink-online');
      LogRocket.getSessionURL(sessionURL => {
        this.$sentry.configureScope(scope => {
          scope.setExtra('sessionURL', sessionURL);
        });
      });
    }

    // Log device props
    window.analytics.track('startSession', {
      browserName,
      hasTouch: detectIt.hasTouch,
      isChrome,
      isFirefox,
      isMobile,
      isMobileSafari,
      isSafari,
      osName,
      osVersion,
    });

    this.setDeviceInfo({
      browserName,
      osName,
      isMobile,
      isChrome,
      isSafari,
      isFirefox,
      hasTouch: detectIt.hasTouch,
    });

    // Display warning when on mobile and not using safari
    if (this.iOsNonSafari) {
      this.setBrowserWarningDialogVisibility(true);

      window.analytics.track('issuedBrowserWarning', this.device);
    }

    // Update state after successful log in
    this.$auth.onAuthStateChanged((firebaseUser) => {
      if (firebaseUser) {
        this.setLoginDialogVisibility(false);

        addUserUIDtoLogRocket(firebaseUser.uid);

        LogRocket.getSessionURL(sessionURL => {
          this.$sentry.configureScope((scope) => {
            scope.setUser({
              id: firebaseUser.uid,
            });
            scope.setExtra('sessionURL', sessionURL);
          });
        });

        this.setUserData({
          displayName: firebaseUser.displayName,
          email: firebaseUser.email,
          photoURL: firebaseUser.photoURL,
          uid: firebaseUser.uid,
        });

        window.analytics.identify(firebaseUser.uid);
      }
    });
  },
  methods: {
    ...mapMutations([
      'setDeviceInfo',
      'setBrowserWarningDialogVisibility',
      'setLoginDialogVisibility',
      'setUserData',
      'closeAlert',
    ]),
  },
};
</script>

<style lang="stylus" scoped>
.toolbar__logo
  width 220px
  margin-right 25px

.toolbar__date
  color rgba(47, 176, 255, 0.5)

.toolbar__date-inner
  margin-right 20px

.session-recording-info
  padding-left 20px
</style>
