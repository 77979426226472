var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-navigation-drawer",
    {
      staticClass: "light-blue darken-4",
      staticStyle: { "z-index": "100" },
      attrs: {
        width: "100%",
        dark: "",
        stateless: "",
        "disable-resize-watcher": "",
        app: "",
        right: ""
      },
      model: {
        value: _vm.userDrawer,
        callback: function($$v) {
          _vm.userDrawer = $$v
        },
        expression: "userDrawer"
      }
    },
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            { staticClass: "fill-height" },
            [
              _c(
                "v-list-item",
                {
                  staticClass: "drawer-close-button",
                  attrs: { link: "" },
                  on: {
                    click: function($event) {
                      return _vm.setUserDrawerActive(false)
                    }
                  }
                },
                [_c("v-list-item-icon", [_c("v-icon", [_vm._v("close")])], 1)],
                1
              ),
              _c("v-col", [
                _c("h2", { staticClass: "mb-8" }, [
                  _vm._v(" Opptak fra timer ")
                ]),
                _vm.recordings.length
                  ? _c(
                      "div",
                      _vm._l(_vm.recordings, function(recording) {
                        return _c(
                          "div",
                          { key: recording.ID, staticClass: "mb-5" },
                          [
                            _c("p", [
                              _vm._v(_vm._s(_vm.getRecordingDate(recording)))
                            ]),
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  color: "#009bff",
                                  loading: !recording.downloadURL,
                                  disabled: !recording.downloadURL
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.openVideo(recording.downloadURL)
                                  }
                                }
                              },
                              [_vm._v(" Åpne ")]
                            ),
                            !recording.downloadURL
                              ? _c("p", { staticClass: "helptext" }, [
                                  _vm._v(
                                    " Klargjør opptak. Vennligst vent. Dette kan ta opp til 5 minutter. "
                                  )
                                ])
                              : _vm._e()
                          ],
                          1
                        )
                      }),
                      0
                    )
                  : _c("div", [_c("p", [_vm._v("Ingen opptak ennå.")])])
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }