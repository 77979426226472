var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { color: "transparent", flat: "" } },
    [
      _c("v-card-title", [
        _c("h1", { staticClass: "headline" }, [_vm._v(" Velkommen tilbake! ")]),
        _c("p", { staticClass: "sub-headline" }, [
          _vm._v(" Logg inn med Learnlink-kontoen din ")
        ])
      ]),
      _c(
        "v-card-text",
        [
          _vm.alert
            ? _c(
                "v-container",
                [
                  _c("v-alert", { attrs: { type: "error" } }, [
                    _vm._v(" " + _vm._s(_vm.alert) + " ")
                  ])
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-container",
            { staticClass: "form-container" },
            [
              _c(
                "v-col",
                [
                  _c("v-text-field", {
                    attrs: { label: "Epost", type: "email", required: "" },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.signInUsingEmail($event)
                      }
                    },
                    model: {
                      value: _vm.email,
                      callback: function($$v) {
                        _vm.email = $$v
                      },
                      expression: "email"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: { label: "Passord", type: "password", required: "" },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.signInUsingEmail($event)
                      }
                    },
                    model: {
                      value: _vm.password,
                      callback: function($$v) {
                        _vm.password = $$v
                      },
                      expression: "password"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary" },
                  on: { click: _vm.signInUsingEmail }
                },
                [_vm._v(" Logg inn ")]
              ),
              _c("v-btn", { attrs: { text: "" }, on: { click: _vm.cancel } }, [
                _vm._v(" Avbryt ")
              ])
            ],
            1
          )
        ],
        1
      ),
      _c("v-card-actions", { staticClass: "d-flex justify-center" }, [
        _c(
          "button",
          {
            staticClass: "loginBtn loginBtn--facebook",
            on: {
              click: function($event) {
                return _vm.signInUsing("facebook")
              }
            }
          },
          [_vm._v(" Facebook ")]
        ),
        _c(
          "button",
          {
            staticClass: "loginBtn loginBtn--google",
            on: {
              click: function($event) {
                return _vm.signInUsing("google")
              }
            }
          },
          [_vm._v(" Google ")]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }