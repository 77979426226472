<template>
  <v-card color="transparent" flat>
    <v-card-title>
      <h1 class="headline">
        Velkommen tilbake!
      </h1>
      <p class="sub-headline">
        Logg inn med Learnlink-kontoen din
      </p>
    </v-card-title>

    <v-card-text>
      <v-container v-if="alert">
        <v-alert type="error">
          {{ alert }}
        </v-alert>
      </v-container>

      <v-container class="form-container">
        <v-col>
          <v-text-field
            v-model="email"
            label="Epost"
            type="email"
            required
            @keyup.enter.native="signInUsingEmail"
          />
          <v-text-field
            v-model="password"
            label="Passord"
            type="password"
            required
            @keyup.enter.native="signInUsingEmail"
          />
        </v-col>
        <v-btn color="primary" @click="signInUsingEmail">
          Logg inn
        </v-btn>
        <v-btn text @click="cancel">
          Avbryt
        </v-btn>
      </v-container>
    </v-card-text>

    <v-card-actions class="d-flex justify-center">
      <button
        class="loginBtn loginBtn--facebook"
        @click="signInUsing('facebook')"
      >
        Facebook
      </button>

      <button class="loginBtn loginBtn--google" @click="signInUsing('google')">
        Google
      </button>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'Login',
  data() {
    return {
      alert: '',
      email: '',
      password: '',
    }
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    signInUsing(provider) {
      if (provider === 'facebook') {
        provider = new this.$providers.FacebookAuthProvider()
      } else if (provider === 'google') {
        provider = new this.$providers.GoogleAuthProvider()
      }

      return this.$auth
        .signInWithPopup(provider)
        .catch((e) => this.$handleError(e))
    },
    signInUsingEmail() {
      return this.$auth
        .signInWithEmailAndPassword(this.email, this.password)
        .catch((e) => {
          if (e.code === 'auth/invalid-email') {
            this.alert = 'Ugyldig epostadresse.'
          } else if (e.code === 'auth/wrong-password') {
            this.alert = 'Feil passord'
          } else this.alert = e.message

          this.$handleError(e)
        })
    },
  },
}
</script>

<style scoped>
/* Shared */
.loginBtn {
  box-sizing: border-box;
  position: relative;
  /* width: 13em;  - apply for fixed size */
  margin: 0.2em;
  padding: 0 15px 0 46px;
  border: none;
  text-align: left;
  line-height: 34px;
  white-space: nowrap;
  border-radius: 0.2em;
  font-size: 16px;
  color: #fff;
  width: 150px;
}

.loginBtn:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 34px;
  height: 100%;
}

.loginBtn:focus {
  outline: none;
}

.loginBtn:active {
  box-shadow: inset 0 0 0 32px rgba(0, 0, 0, 0.1);
}

/* Facebook */
.loginBtn--facebook {
  background-color: #4c69ba;
  /*font-family: "Helvetica neue", Helvetica Neue, Helvetica, Arial, sans-serif;*/
  text-shadow: 0 -1px 0 #354c8c;
}

.loginBtn--facebook:before {
  border-right: #364e92 1px solid;
  background: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_facebook.png')
    6px 6px no-repeat;
}

.loginBtn--facebook:hover,
.loginBtn--facebook:focus {
  background-color: #5b7bd5;
}

/* Google */
.loginBtn--google {
  /*font-family: "Roboto", Roboto, arial, sans-serif;*/
  background: #dd4b39;
}

.loginBtn--google:before {
  border-right: #bb3f30 1px solid;
  background: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_google.png')
    6px 6px no-repeat;
}

.loginBtn--google:hover,
.loginBtn--google:focus {
  background: #e74b37;
}

.sub-headline {
  margin: 10px 0 0 auto;
  font-size: 12px;
}

.form-container {
  justify-content: center;
  display: flex;
  flex-direction: column;
}
</style>
