import Vue from 'vue';
import Vuex from 'vuex';

import LogRocket from 'logrocket';
import createPlugin from 'logrocket-vuex';

const logrocketPlugin = createPlugin(LogRocket);

Vue.use(Vuex);

const initialState = {
  session: {
    recording: false,
    active: false,
  },
  videoChat: {
    joined: false,
    active: false,
  },
  selectedInputDevices: {
    video: null,
    audio: null,
  },
  device: {
    browserName: '',
    hasTouch: false,
    isChrome: false,
    isMobile: false,
    isSafari: false,
    isFirefox: false,
    osName: '',
  },
  ui: {
    alert: {
      active: false,
      headline: '',
      message: '',
    },
    browserWarningDialog: false,
    helpDrawer: false,
    loadingVideoChat: false,
    loginDialogVisible: false,
    showToolbar: true,
    userDrawer: false,
    welcomeDialogVisible: false,
  },
  user: {
    displayName: '',
    email: '',
    photoURL: '',
    uid: '',
  },
};

const store = new Vuex.Store({
  strict: true,
  plugins: [logrocketPlugin],
  state: initialState,
  mutations: {
    setDeviceInfo(state, payload) {
      state.device = {
        ...state.device,
        ...payload,
      };
    },
    setUserData(state, payload) {
      state.user = {
        ...state.user,
        ...payload,
      };
    },
    setVideoInput(state, payload) {
      console.log('setVideoInput');
      console.log(payload);
      state.selectedInputDevices.video = payload;
    },
    setAudioInput(state, payload) {
      state.selectedInputDevices.audio = payload;
    },
    setBrowserWarningDialogVisibility(state, payload) {
      state.ui.browserWarningDialog = payload;
    },
    setLoginDialogVisibility(state, payload) {
      state.ui.loginDialogVisible = payload;
    },
    setWelcomeDialogVisibility(state, payload) {
      state.ui.welcomeDialogVisible = payload;
    },
    setSessionActive(state, payload) {
      state.session.active = payload;
    },
    setSessionRecordingStatus(state, payload) {
      state.session.recording = payload;
    },
    setLoadingVideoChat(state, payload) {
      state.ui.loadingVideoChat = payload;
    },
    setVideoChatActive(state, payload) {
      state.videoChat.active = payload;
    },
    setUserDrawerActive(state, payload) {
      state.ui.userDrawer = payload;
    },
    displayAlert(state, payload) {
      state.ui.alert = {
        ...state.ui.alert,
        ...payload,
        active: true,
      };
    },
    closeAlert(state) {
      state.ui.alert = {
        active: false,
        headline: '',
        message: '',
      };
    },
    endCall(state) {
      state.videoChat.active = false;
    },
    signOut(state) {
      state.user = {
        displayName: '',
        email: '',
        photoURL: '',
        uid: '',
      };
    },
  },
  getters: {
    isNonSafariOniOS(state) {
      if (!state.device || !(state.device && state.device.osName)) return false
      return (state.device.osName === 'iOS' || (state.device.osName === 'Mac OS' && state.device.hasTouch)) && !state.device.isSafari
    }
  },
});

export default store;
